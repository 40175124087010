import axios from "axios";

const baseUrl = "https://api.ratebotai.com:8443";

export const getCheckinOTP = async (payload) => {
  const response = await axios.post(
    baseUrl + "/check_booking_and_mobile_pms",
    payload
  );
  return response.data;
};

export const validateCheckinOTP = async (otp, mobile_no, booking_id) => {
  const response = await axios.post(
    baseUrl + "/validate_pms_mobile_otp",
    {
      otp: otp,
      mobile_no: mobile_no,
      booking_id: booking_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const getRoomDetails = async () => {
  const response = await axios.post(baseUrl + "/get_room_type_data", {
    hotel_code: 100087,
  });
  return response.data;
};

export const getRoomNumbers = async (id) => {
  const response = await axios.post(
    baseUrl + "/check_rooms_availability_for_pms",
    {
      from_date: "2024-5-9",
      pms_hotel_code: 100087,
      room_type_id: id,
      to_date: "2024-5-11",
    }
  );
  return response.data;
};

export const selfCheckInPmsNormal = async (payload) => {
  const response = await axios.post(
    baseUrl + "/self_check_in_pms_normal",
    payload
  );
  return response.data;
};

export const getAadharValidation = async (aadharNumber, booking_id) => {
  const response = await axios.post(baseUrl + "/get_aadhaar_otp_pms", {
    aadhaar_number: aadharNumber,
    booking_id: booking_id,
  });
  return response.data;
};

export const selfCheckInPms = async (payload) => {
  const response = await axios.post(
    baseUrl + "/self_check_in_pms_aadhar",
    payload
  );
  return response.data;
};
