import React, { useContext, useState } from "react";
import Navbar from "./navbar";
import front_photo from "./assets/front_photo.jpg";
import { useNavigate } from "react-router-dom";
import { getAadharValidation, selfCheckInPms } from "./apis/api";
import SimpleSnackbar from "./snackBar";
import AuthContext from "../AuthContext";
import TermsPopup from "./termsPopup";

export default function AadharValidation() {
  const Navigate = useNavigate();
  const hotelCode = window.location.pathname.split("/")[1];
  const [otpRequested, setOtpRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ visible: false, message: "" });
  const { userData } = useContext(AuthContext) || { booking_id: "" };
  const [showTerms, setShowTerms] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [payLoadAadhaar, setPayLoadAadhaar] = useState({
    aadharNumber: "",
    otpAadhaar: "",
  });

  const handleSubmit = async () => {
    if (payLoadAadhaar.otpAadhaar.length === 6) {
      setLoading(true);
      const res = await selfCheckInPms({
        aadhaar_no: payLoadAadhaar.aadharNumber,
        otp: payLoadAadhaar.otpAadhaar,
        booking_id: userData?.booking_id,
      });
      setLoading(false);
      if (res.status_message == "success") {
        Navigate(`/${hotelCode}/confirmation`);
      } else if (res.status !== "failed") {
        setSnackbar({ visible: true, message: res.message });
        Navigate(`/${hotelCode}/helpdesk`);
      } else {
        setSnackbar({
          visible: true,
          message: "Contact Frontdesk for futher details.",
        });
        Navigate(`/${hotelCode}/helpdesk`);
      }
    } else {
      setSnackbar({
        visible: true,
        message: "Please enter valid OTP",
      });
    }
  };

  const handleOnClick = async () => {
    if (!termsChecked) {
      setSnackbar({
        visible: true,
        message: "Please agree to Terms and Conditions",
      });
      return;
    } else if (!payLoadAadhaar.aadharNumber) {
      setSnackbar({
        visible: true,
        message: "Please enter aadhar number",
      });
      return;
    }
    try {
      setLoading(true);
      const res = await getAadharValidation(
        payLoadAadhaar.aadharNumber,
        userData?.booking_id
      );
      setLoading(false);
      if (res.status === "success") {
        setOtpRequested(true);
        setSnackbar({
          visible: true,
          message: "Otp sent to your registered mobile numbers",
        });
      } else {
        setSnackbar({
          visible: true,
          message: res.message,
        });
      }
    } catch (error) {
      setSnackbar({ visible: true, message: "Try after some time" });
    }
  };

  const toggleTermsPopup = () => {
    setShowTerms(!showTerms);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  return (
    <div className="max-w-screen mx-auto">
      <Navbar username="" />
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 backdrop-blur-lg z-50">
          <div className="loader border-4 border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
        </div>
      )}
      <TermsPopup
        show={showTerms}
        toggle={toggleTermsPopup}
        title="Terms and Conditions"
        content="I hereby consent and authorize RateBotAI to obtain my Aadhaar number, Biometric and/or One Time 
                Pin (OTP) and authenticate my demographic details such as name, gender, address, and 
                photograph/eKYC etc. from UIDAI.
                I further consent to the use of Aadhaar-based authentication by RateBotAI, for processing my web 
                based check in at hotel associated with RateBotAI technology .I acknowledge and understand that 
                the demographic data or eKYC information received from UIDAI will recorded as KYC details and 
                would be used to process my check in at hotels"
      />
      <div
        style={{
          backgroundImage: `url(${front_photo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "92.5vh",
        }}
        className=" mt-16 rounded p-4 bg-white shadow-lg grid sm:grid-cols-1 md:grid md:grid-cols-1 md:grid-rows-3 md:gap-4 h-screen items-center"
      >
        <section
          className="rounded-3xl md:pt-96"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div
              className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <div className="text-xl font-bold leading-tight flex justify-center items-center tracking-tight  text-1xl  text-white">
                  Check-In using Aadhar Card
                </div>
                <div className="space-y-4 md:space-y-6">
                  <div className="relative flex flex-col items-center">
                    <div className="relative flex flex-row items-center">
                      <input
                        type="number"
                        id="booking_id_input"
                        aria-describedby="helper-text-explanation"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-5 p-2.5 transition duration-200 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 m-1"
                        placeholder="Aadhar Number"
                        style={{ appearance: "textfield" }}
                        onChange={(e) => {
                          setPayLoadAadhaar({
                            ...payLoadAadhaar,
                            aadharNumber: e.target.value,
                          });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <div>
                        <button
                          type="button"
                          className={`rounded border-2 px-4 py-2 m-2 h-10 w-35 whitespace-nowrap ${
                            !otpRequested ? "text-white" : "text-gray-400"
                          }`}
                          disabled={otpRequested}
                          onClick={handleOnClick}
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center text-sm">
                      <input
                        type="checkbox"
                        id="!termstermsAndConditions"
                        className="rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        disabled={otpRequested}
                        onChange={handleTermsChange}
                      />
                      <label
                        htmlFor="termsAndConditions"
                        className="ml-2 text-white"
                      >
                        I agree to the{" "}
                        <button
                          onClick={toggleTermsPopup}
                          className="underline"
                        >
                          Terms and Conditions
                        </button>
                      </label>
                    </div>

                    {otpRequested && (
                      <div className="flex flex-col justify-center  items-center rounded mt-2">
                        <div className="relative text-white ">
                          Enter Verification Code
                        </div>
                        <div className="flex flex-col justify-center  items-center rounded">
                          <div className="relative mt-3">
                            <input
                              type="number"
                              pattern="[0-9]*"
                              value={payLoadAadhaar.otpAadhaar}
                              onChange={(e) =>
                                setPayLoadAadhaar({
                                  ...payLoadAadhaar,
                                  otpAadhaar: e.target.value,
                                })
                              }
                              required
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 lg:ps-5 md:ps-5 sm:ps-5 p-2.5 transition duration-200 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-9/10 m-1"
                              placeholder="Enter OTP"
                            />
                          </div>
                          <div className="flex justify-center mt-6">
                            <button
                              type="button"
                              className="border-2 text-white rounded px-4 py-2 m-2"
                              style={{
                                backgroundColor: "gray-500",
                              }}
                              onClick={() => {
                                handleSubmit();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-center items-center my-4">
                    <hr className="w-full border-gray-300" />
                    <span className="px-4 text-white">OR</span>
                    <hr className="w-full border-gray-300" />
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        Navigate(`/${hotelCode}/check_in_form`);
                      }}
                      className="px-5 py-2 bg-gray-500 text-white  rounded-full cursor-pointer"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {snackbar.visible && (
          <SimpleSnackbar
            message={snackbar.message}
            setSnackbar={setSnackbar}
          />
        )}
      </div>
    </div>
  );
}
